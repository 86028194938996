import { Link, graphql } from 'gatsby';
import { formatPostDate, formatReadingTime } from '../utils/helpers';

import Bio from '../components/Bio';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Panel from '../components/Panel';
import React from 'react';
import SEO from '../components/SEO';
import get from 'lodash/get';
import { rhythm } from '../utils/typography';

class BlogTagTemplate extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const { langKey, tag, total } = this.props.pageContext;

    const posts = get(this, 'props.data.allMarkdownRemark.edges');

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO />
        <main
          style={{
            flex: 1,
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: rhythm(28),
            padding: '0 24px',
            width: '100%',
            marginTop: '6rem',
          }}
        >
          <h1 style={{ marginBottom: 0, fontSize: rhythm(0.9) }}>
            <span style={{ color: '#666' }}>#</span>
            {tag}
          </h1>
          <span style={{ color: '#aaa', fontSize: rhythm(0.5) }}>
            total {total} {total > 1 ? 'posts' : 'post'}
          </span>
          {posts.map(({ node }) => {
            const title = get(node, 'frontmatter.title') || node.fields.slug;
            return (
              <article key={node.fields.slug}>
                <header>
                  <h3
                    style={{
                      fontSize: rhythm(0.8),
                      marginBottom: rhythm(1 / 4),
                    }}
                  >
                    <Link
                      style={{ boxShadow: 'none' }}
                      to={node.fields.slug}
                      rel="bookmark"
                    >
                      {title}
                    </Link>
                  </h3>
                  <small
                    style={{
                      color: '#666',
                    }}
                  >
                    {formatPostDate(node.frontmatter.date, langKey)}
                    {` • `}{' '}
                    <span title="以每分钟265计算">
                      {formatReadingTime(node.timeToRead)}
                    </span>
                  </small>
                </header>
                <p
                  dangerouslySetInnerHTML={{ __html: node.frontmatter.spoiler }}
                />
              </article>
            );
          })}
        </main>
        <Footer />
      </Layout>
    );
  }
}

export default BlogTagTemplate;

export const pageQuery = graphql`
  query($langKey: String!, $tag: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: {
        fields: { langKey: { eq: $langKey } }
        frontmatter: { tags: { in: [$tag] } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            langKey
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            spoiler
            tags
          }
        }
      }
    }
  }
`;
